<template>
  <b-form-group
    label="Gender"
    label-for="gender"
  >
    <b-form-select
      name="gender"
      :options="options"
      :value="value"
      v-bind="$attrs"
      v-on="$listeners"
    />
  </b-form-group>
</template>

<script>
import { BFormGroup, BFormSelect } from 'bootstrap-vue'
import { title } from '@/@core/utils/filter'

export default {
  components: {
    BFormGroup,
    BFormSelect,
  },
  props: {
    value: {
      type: String,
      default: null,
    },
    league: {
      type: Boolean,
      default: false,
    },
    optional: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    options() {
      const leagueGenders = ['male', 'female', 'male & female']
      const playerGenders = ['male', 'female']

      const options = this.league ? leagueGenders : playerGenders

      return [
        // Add an optional "-" option if gender is optional for players.
        this.optional ? { text: '-', value: null } : { text: 'Select a gender', value: null, disabled: true },
        ...options.map(gender => ({
          text: title(gender),
          value: gender,
        })),
      ]
    },
  },
}
</script>

<style scoped>

</style>
