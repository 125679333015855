<template>
  <section>
    <!-- Photo -->
    <image-input
      :file.sync="player.photo"
      :preview-src.sync="player.photo_url"
      label="Player Photo"
    />

    <b-row class="mb-2">
      <!-- First Name -->
      <b-col sm="4">
        <form-validation-field
          v-model="player.first_name"
          vid="first_name"
          name="first_name"
          label="First Name*"
          placeholder="First Name"
          aria-autocomplete="none"
          rules="required"
        />
      </b-col>

      <!-- Last Name -->
      <b-col sm="4">
        <form-validation-field
          v-model="player.last_name"
          vid="last_name"
          name="last_name"
          label="Last Name*"
          placeholder="Last Name"
          aria-autocomplete="none"
          rules="required"
        />
      </b-col>

      <!-- Gender -->
      <b-col sm="4">
        <select-gender
          v-model="player.gender"
          optional
        />
      </b-col>

      <!-- Birthdate -->
      <b-col sm="4">
        <b-form-group
          label="Birthdate"
          label-for="birthdate"
        >
          <flat-pickr
            id="birthdate"
            v-model="player.birthdate"
            name="birthdate"
            placeholder="Select Birthdate"
            class="form-control"
            :config="{
              altInput: true,
            }"
          />
        </b-form-group>
      </b-col>

      <!-- Height -->
      <b-col sm="4">
        <b-form-group
          label="Height (Ft) ex: 6'1"
          label-for="height"
        >
          <b-form-input
            id="height"
            v-model="player.height"
            name="height"
            placeholder="Height"
            aria-autocomplete="none"
          />
        </b-form-group>
      </b-col>

      <!-- Weight -->
      <b-col sm="4">
        <b-form-group
          label="Weight (Lbs)"
          label-for="weight"
        >
          <b-form-input
            id="weight"
            v-model="player.weight"
            name="weight"
            placeholder="Weight"
            aria-autocomplete="none"
          />
        </b-form-group>
      </b-col>

      <!-- School -->
      <b-col sm="4">
        <b-form-group
          label="School"
          label-for="school"
        >
          <b-form-input
            id="school"
            v-model="player.school"
            name="school"
            placeholder="School"
            aria-autocomplete="none"
          />
        </b-form-group>
      </b-col>

      <!-- Email -->
      <b-col sm="4">
        <form-validation-field
          v-model="player.email"
          rules="email"
          vid="email"
          name="email"
          label="Email"
          placeholder="Email"
          aria-autocomplete="none"
        />
      </b-col>

      <!-- Phone Number -->
      <b-col sm="4">
        <b-form-group
          label="Phone Number"
          label-for="phone-number"
        >
          <b-form-input
            id="phone-number"
            v-model="player.phone_number"
            name="phone-number"
            placeholder="Phone Number"
            aria-autocomplete="none"
          />
        </b-form-group>
      </b-col>

      <!-- GPA -->
      <b-col sm="4">
        <form-validation-field
          v-model="player.gpa"
          rules="double|max:3"
          vid="gpa"
          name="GPA"
          label="GPA"
          placeholder="GPA"
          aria-autocomplete="none"
        />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import FormValidationField from '@/components/forms/validation/FormValidationField.vue'
import ImageInput from '@/components/forms/images/ImageInput.vue'
import flatPickr from 'vue-flatpickr-component'
import SelectGender from '@/components/forms/selects/SelectGender.vue'

export default {
  name: 'FormPlayerInfo',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    ImageInput,
    FormValidationField,
    flatPickr,
    SelectGender,
  },
  props: {
    player: {
      required: true,
      type: Object,
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
