var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[(_vm.$route.query.event)?_c('button-back',{on:{"click":_vm.goBack}},[_vm._v(" Back to League ")]):_vm._e(),_c('validation-form',{ref:"validationForm",attrs:{"submit-buttons":{
      loading: _vm.form.isLoading,
      cancel: _vm.$route.query.event !== null,
      saveAndNew: _vm.isNewPlayer
    }},on:{"cancel":_vm.goBack,"validated":function($event){_vm.isNewPlayer ? _vm.createPlayer() : _vm.updatePlayer()}}},[_c('b-card',[_c('form-player-info',{attrs:{"player":_vm.form}}),_c('form-social-media',{attrs:{"social-media":_vm.form.social_media || undefined},on:{"update:social-media":function($event){_vm.form.social_media = $event}}})],1)],1),(!_vm.isNewPlayer)?_c('card-delete',{attrs:{"item-name":"player","alert-body":"Once you delete this player, past records from all seasons will be permanently deleted. This action cannot be undone."},on:{"delete":function($event){return _vm.$store.commit('app/deleteModal/UPDATE_DELETE_MODAL',{
      title: 'Delete Player',
      itemName: _vm.form.full_name,
      endpoint: ("/players/" + (_vm.form.id)),
      afterDelete: _vm.goBack
    })}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }