<template>
  <section>
    <button-back
      v-if="$route.query.event"
      @click="goBack"
    >
      Back to League
    </button-back>

    <validation-form
      ref="validationForm"
      :submit-buttons="{
        loading: form.isLoading,
        cancel: $route.query.event !== null,
        saveAndNew: isNewPlayer
      }"
      @cancel="goBack"
      @validated="isNewPlayer ? createPlayer() : updatePlayer()"
    >
      <b-card>
        <!-- Player Info -->
        <form-player-info :player="form" />

        <!-- Social Media -->
        <form-social-media
          :social-media="form.social_media || undefined"
          @update:social-media="form.social_media = $event"
        />
      </b-card>
    </validation-form>

    <!-- Delete -->
    <card-delete
      v-if="!isNewPlayer"
      item-name="player"
      alert-body="Once you delete this player, past records from all seasons will be permanently deleted. This action cannot be undone."
      @delete="$store.commit('app/deleteModal/UPDATE_DELETE_MODAL',{
        title: 'Delete Player',
        itemName: form.full_name,
        endpoint: `/players/${form.id}`,
        afterDelete: goBack
      })"
    />
  </section>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import ButtonBack from '@/components/buttons/ButtonBack.vue'
import ValidationForm from '@/components/forms/validation/ValidationForm.vue'
import FormPlayerInfo from '@/components/forms/FormPlayerInfo.vue'
import FormSocialMedia from '@/components/forms/FormSocialMedia.vue'
import CardDelete from '@/components/cards/CardDelete.vue'

import { mapState } from 'vuex'
import Form from '@/forms/Form'
import axios from '@/libs/axios'
import useFlash from '@/composables/useFlash'

const { flashError } = useFlash()

export default {
  name: 'EventPlayer',
  components: {
    BCard,
    ButtonBack,
    ValidationForm,
    FormPlayerInfo,
    FormSocialMedia,
    CardDelete,
  },
  data() {
    return {
      form: new Form({
        first_name: '',
        last_name: '',
        photo: undefined,
        photo_url: '',
        height: null,
        weight: null,
        birthdate: null,
        school: null,
        email: null,
        phone_number: null,
        gpa: null,
        social_media: null,
      }, true),
    }
  },
  computed: {
    ...mapState('event', ['event']),
    isNewPlayer() {
      return !this.$route.params.player
    },
  },
  beforeRouteEnter(to, from, next) {
    axios.get(`/players/${to.params.player}`)
      .then(response => {
        const { data } = response.data

        next(vm => {
          Object.assign(vm.form, data)
        })
      })
      .catch(error => {
        flashError(error)
      })
  },
  mounted() {
    this.form.validationObserver = this.$refs.validationForm.$refs.observer
  },
  methods: {
    goBack() {
      this.$router.back()
      // this.$router.push({
      //   name: 'event.players',
      //   params: { event: this.$route.query.event },
      // })
    },
    createPlayer(createNew) {
      this.form.post(`/players&organization_id=${this.event.organization_id}`, true)
        .then(response => {
          if (createNew) {
            document.getElementById('first-name').focus()
            return
          }

          const { data } = response

          Object.assign(this.form, data)
          this.$router.push({ name: 'player', params: { player: data.id } })
        })
    },
    updatePlayer() {
      this.form.post(`/players/${this.$route.params.player}}`, false, true)
        .then(() => {
          this.goBack()
        })
    },
  },
}
</script>
